import { useState } from 'react';

import { DomuiError, DynamicData } from '@domui-domain/type';
import Individual from '@domui-utils/workflow/individual';

// Custom hook to handle API calls
export const useFetchProfileDetails = () => {
  const [data, setData] = useState<DynamicData>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<DomuiError>(undefined);

  const fetchProfileDetails = async (dominoId: string) => {
    setLoading(true);
    setError(null); // Clear error before new request

    try {
      const wfService = new Individual();
      const profileInfo = await wfService.getProfileInfo(dominoId);
      if (!profileInfo?.id) {
        setError({
          message: 'Error while retrive the profile info',
        });
      }
      setData({
        personalDetails: {
          ...profileInfo,
          dateOfBirth: profileInfo.dob,
          sharingPermissionsExtended: {},
          editPermissions: {
            photo: false,
          },
        },
      });
    } catch (err) {
      setError(err as DomuiError);
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, fetchProfileDetails };
};

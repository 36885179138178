import { useState } from 'react';

import { DomuiError, DynamicData } from '@domui-domain/type';
import Individual from '@domui-utils/workflow/individual';

// Custom hook to handle API calls
export const useFetchContactInfo = () => {
  const [data, setData] = useState<DynamicData>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<DomuiError>(null);
  const fetchContactInfo = async (dominoId: string) => {
    // Clear error before new request

    try {
      if (dominoId) {
        setLoading(true);
        setError(null);
        const wfService = new Individual();
        const res = await wfService.getContactInfo(dominoId);
        setData(res?.contactInfo);
      }
    } catch (err) {
      setError(err as DomuiError);
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, fetchContactInfo };
};

import { useState } from 'react';

import { useNotifications } from '@use-cases/notifications';

import { localizedNavigate } from '@utils/localized-navigate';

import { DomuiError, DynamicData } from '@domui-domain/type';
import Individual from '@domui-utils/workflow/individual';

import { useTranslation } from '@external/react-i18next';

// Custom hook to handle API calls
export const useUpdateContactInfo = () => {
  const [data, setData] = useState<DynamicData>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<DomuiError>(null);
  const { addSuccess } = useNotifications();
  const { t } = useTranslation();

  const updateContactInfo = async (
    individualData: string,
    updateData: DynamicData
  ) => {
    try {
      if (updateData) {
        setLoading(true);
        setError(null); // Clear error before new request
        const wfService = new Individual();
        const response = await wfService.updateContactInfo(
          individualData,
          updateData
        );
        setData(response);
        localizedNavigate(`/domui/profile/${individualData}`);
        addSuccess(
          t('edit-personal-details.form.success', 'Update successful.'),
          {
            id: 'form.success',
          }
        );
      }
    } catch (err) {
      setError(err as DomuiError);
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, updateContactInfo };
};

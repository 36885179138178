import { useState } from 'react';

import { useNotifications } from '@use-cases/notifications';

import { localizedNavigate } from '@utils/localized-navigate';

import { DomuiError, DynamicData } from '@domui-domain/type';
import Individual from '@domui-utils/workflow/individual';

import { useTranslation } from '@external/react-i18next';

// Custom hook to handle API calls
export const useUpdateAreasOfExpertise = () => {
  const [data, setData] = useState<DynamicData>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<DomuiError>(undefined);
  const { addSuccess } = useNotifications();
  const { t } = useTranslation();

  const updateAreasOfExpertise = async (areasOfExpertiseInfo: DynamicData) => {
    try {
      if (areasOfExpertiseInfo) {
        setLoading(true);
        setError(null);
        const wfService = new Individual();
        const response = await wfService.updateAreasOfExpertise(
          areasOfExpertiseInfo.dominoId,
          areasOfExpertiseInfo.sharingPermissionId,
          areasOfExpertiseInfo.findMissingAndCombineObjects
        );
        setData(response);
        setLoading(false);
        localizedNavigate(`/domui/profile/${areasOfExpertiseInfo.dominoId}`);
        addSuccess(
          t('edit-professional-experience.form.success', 'Update successful.'),
          {
            id: 'form.success',
          }
        );
      }
    } catch (err) {
      setError(err as DomuiError);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, updateAreasOfExpertise };
};

import { useState } from 'react';

import Individual from '../utils/workflow/individual';

import { DomuiError, DynamicData } from '@domui-domain/type';

type updateBackgroundArgs = {
  data: DynamicData;
};

// Custom hook to handle API calls
export const useUpdateBackground = () => {
  const [data, setData] = useState<DynamicData>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<DomuiError>(undefined);

  const updateBackground = async ({ data }: updateBackgroundArgs) => {
    let updateStatus = false;
    try {
      setLoading(true);
      setError(undefined); // Clear error before new request
      const wfService = new Individual();
      const response = await wfService.updateEditBackground(data);
      setData(response);
      updateStatus = true;
    } catch (err) {
      setError(err as DomuiError);
      updateStatus = false;
    } finally {
      setLoading(false);
    }
    return updateStatus;
  };

  return { data, loading, error, updateBackground };
};

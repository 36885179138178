import React, { useEffect, useState } from 'react';

import { RouteComponentProps } from '@reach/router';
import { Form, Formik } from 'formik';

import { Button } from '@components/Button';
import TextArea from '@components/Formik/TextArea';
import { OneColumn } from '@components/Layouts/OneColumn';
import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import Title from '@components/Title';

import { backgroundValidationSchema } from '../validationSchema';

import { MAX_BACKGROUND_CHAR } from '@domain/profile';

import { useErrorHandling, useNotifications } from '@use-cases/notifications';

import { localizedNavigate } from '@utils/localized-navigate';

import SharingPermissionSelect from '@domui-components/Formik/Select/SharingPermissionSelect';
import { DynamicData } from '@domui-domain/type';
import { useFetchIndividual } from '@domui-hooks/useFetchIndividual';
import { useUpdateBackground } from '@domui-hooks/useUpdateBackground';
import Metadata from '@domui-utils/workflow/metadata';

import { useTranslation } from '@external/react-i18next';

interface Props extends RouteComponentProps {
  individualId?: string;
}

const BackgroundEdit: React.FC<Props> = ({ individualId }) => {
  const { t } = useTranslation();

  const [sharingPermissionData, setSharingPermissionData] = useState([]);
  const { addError, addSuccess } = useNotifications();

  const {
    data: individualData,
    loading: individualLoading,
    error: individualError,
    fetchIndividual,
  } = useFetchIndividual();

  const {
    loading: updatedLoading,
    error: updatedError,
    updateBackground,
  } = useUpdateBackground();

  useErrorHandling(
    updatedError?.description
      ? updatedError.description
      : t('edit-background.form.error', 'An error occurred.'),
    !!updatedError,
    'edit-background.form.error'
  );

  useErrorHandling(
    t('get-background-experience.error', 'An error occurred.'),
    !!individualError,
    'fetch.error'
  );

  // useEffect(() => {
  //   if (user?.nfKey) {
  //     fetchDominoId();
  //   }
  // }, [user]);

  useEffect(() => {
    if (individualId) {
      fetchIndividual(individualId);
    }
  }, [individualId]);
  useEffect(() => {
    async function fetchSharingPermissionData() {
      try {
        const wfService = new Metadata();
        const response = await wfService.getSharingPermissionData();
        setSharingPermissionData(response);
      } catch (error) {
        addError((error as Error).message, { id: 'form.error' });
      }
    }
    fetchSharingPermissionData();
  }, []);

  const handleFormSubmit = async (values: DynamicData) => {
    const sharingPermissionId: string =
      values?.sharingPermissionsExtended?.aboutMe?.id;

    const updateStatus = await updateBackground({
      data: {
        id: individualId,
        sharingPermissionId,
        aboutMe: values?.aboutMe,
      },
    });

    if (updateStatus) {
      localizedNavigate(`/domui/profile/${individualId}`);
      addSuccess(t('edit-background.form.success', 'Update successful.'), {
        id: 'form.success',
      });
    }
  };

  const handleCancel = () => {
    localizedNavigate(`/domui/profile/${individualId}`);
  };

  if (individualLoading || updatedLoading || !individualData?.id) {
    return <Loading />;
  }

  const initialValues = {
    aboutMe: individualData.aboutMe,
    sharingPermissionsExtended: {
      aboutMe: {
        id: individualData?.sharingPermission?.aboutMe?.levelId,
      },
    },
  };

  return (
    <OneColumn className="mb-20">
      <Formik
        initialValues={initialValues}
        onSubmit={values => {
          handleFormSubmit(values);
        }}
        validationSchema={backgroundValidationSchema(t)}
      >
        {({ isValid, handleSubmit }) => {
          return (
            <>
              <LinkPrevious
                path={`/domui/profile/${individualId}`}
                label={t('edit-profile.back-link', 'Profile')}
              />
              <Title>{t('edit-background.title', 'Edit Background')}</Title>
              <Form>
                <div className="desktop:flex desktop:flex-row-reverse mt-10 desktop:mt-24">
                  <div className="desktop:flex-1 mb-10 desktop:mb-0">
                    <SharingPermissionSelect
                      selectName="sharingPermissionsExtended.aboutMe.id"
                      sharingPermissionData={sharingPermissionData}
                    />
                  </div>
                  <div className="desktop:flex-2 desktop:mr-24">
                    <TextArea
                      name="aboutMe"
                      label={t(
                        'edit-background.form.about-me-label',
                        'About Me'
                      )}
                      characters={MAX_BACKGROUND_CHAR}
                    />
                  </div>
                </div>
                <div className="max-w-lg">
                  <Button
                    full
                    className="mt-16 mb-6"
                    clickHandler={() => {
                      if (!isValid)
                        addError(
                          t(
                            'edit-background.form.error-msg',
                            'Please correct the invalid fields'
                          ),
                          { id: 'form.error' }
                        );
                      handleSubmit();
                    }}
                  >
                    {t('edit-background.form.submit-label', 'Save Changes')}
                  </Button>
                  <Button type="button" full text clickHandler={handleCancel}>
                    {t('edit-background.form.cancel-label', 'Cancel')}
                  </Button>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </OneColumn>
  );
};

export default BackgroundEdit;

/* eslint-disable @typescript-eslint/no-explicit-any */
type SelectInput = {
  professions: any;
  map(
    arg0: (
      a: any
    ) => {
      isPrimary: boolean;
      id?: Maybe<string>;
      employer?: Maybe<string>;
      occupation?: Maybe<string>;
      position?: Maybe<string>;
    }
  ): unknown;
  sharingPermission?:
    | {
        profession: {
          levelId: string;
        };
      }
    | null
    | undefined;
};

export type Maybe<T> = T | null;
type Profession = {
  id?: Maybe<string>;
  employer?: Maybe<string>;
  occupation?: Maybe<string>;
  position?: Maybe<string>;
  isPrimary: Maybe<boolean>;
};

// Filter out unwanted object properties, like __typename.
export const selectProfessionValues = (
  profession: Profession | undefined
): Profession | null => {
  if (profession === undefined) {
    return null;
  }
  const { position, occupation, employer, id, isPrimary } = profession;
  return {
    position,
    occupation,
    employer,
    id,
    isPrimary,
  };
};

export const mapProfessionalExperienceDataToFormValues = (
  input: SelectInput
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let professionalExperiences: any = [];
  if (input?.professions.length > 0) {
    professionalExperiences = [
      ...professionalExperiences,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ...input.professions.map((a: any) => ({
        ...selectProfessionValues(a),
        // eslint-disable-next-line no-unneeded-ternary
        isPrimary: a.isPrimary ? true : false,
      })),
    ];
  }

  const sharingPermissionsExtended = input.sharingPermission
    ? {
        profession: {
          id: input.sharingPermission.profession.levelId,
        },
      }
    : {
        profession: { id: '' },
      };

  return { professionalExperiences, sharingPermissionsExtended };
};

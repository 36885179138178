import { useState } from 'react';

import Individual from '../utils/workflow/individual';

import { DomuiError, DynamicData } from '@domui-domain/type';

// Custom hook to handle API calls
export const useFetchIndividual = () => {
  const [data, setData] = useState<DynamicData>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<DomuiError>(undefined);

  const fetchIndividual = async (dominoId: string) => {
    // Clear error before new request
    try {
      if (dominoId) {
        setLoading(true);
        setError(undefined);
        const wfService = new Individual();
        const response = await wfService.getDominoIndividual(dominoId);
        setData(response);
        setLoading(false);
      }
    } catch (err) {
      setError(err as DomuiError);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  return { data, loading, error, fetchIndividual };
};

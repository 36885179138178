import { useState } from 'react';

import Individual from '../utils/workflow/individual';

import { DomuiError, DynamicData } from '@domui-domain/type';

type updateProfileArgs = {
  dominoId: string;
  data: DynamicData;
};

// Custom hook to handle API calls
export const useUpdateProfileDetails = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<DomuiError>(null);

  const updateProfileDetails = async ({
    dominoId,
    data,
  }: updateProfileArgs) => {
    let updateStatus = false;
    setLoading(true);
    setError(null); // Clear error before new request

    try {
      const wfService = new Individual();
      await wfService.updateProfileInfo(dominoId, data);
      updateStatus = true;
    } catch (err) {
      setError(err as DomuiError);
      updateStatus = false;
    } finally {
      setLoading(false);
    }
    return updateStatus;
  };

  return { loading, error, updateProfileDetails };
};

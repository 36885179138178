import { selectAreasOfExpertiseValues } from './mapAreasOfExpertiseDataToFormValues';

import { EditExpertisesFormValues } from 'src/domui/presenters/web/pages/Profile/Edit/AreasOfExpertise';

import { isNotNullish } from '@typings/util';

export const mapFormValuesToUpdateAreasOfExpertiseInput = (
  values: EditExpertisesFormValues
) => {
  return {
    expertises: values.areasOfExpertise
      .map(selectAreasOfExpertiseValues)
      .filter(isNotNullish),
    sharingPermissionsExtended: values.sharingPermissionsExtended
      ? { expertise: values.sharingPermissionsExtended.expertise.id }
      : null,
  };
};

// Libs
import React from 'react';

import { FieldArray } from 'formik';

import PhoneField from './PhoneField';
import AddAnotherButton from '../../components/ButtonAddAnother';
import DeleteButton from '../../components/ButtonDelete';
import PrimaryRadio from '../../components/PrimaryRadio';
import { getNewPhoneNumber } from '../utils';

import { ContactInformationFormValues } from '@domain/profile';

import { useTranslation } from '@external/react-i18next';

interface Props {
  phones: ContactInformationFormValues['phones'];
  selectPrimary: (selectedIndex: number) => void;
}

const Form: React.FC<Props> = ({ phones, selectPrimary }) => {
  const { t } = useTranslation();

  return (
    <div className="max-w-lg">
      <FieldArray
        name="phones"
        render={({ push, remove }) => (
          <>
            {phones?.map((p, i) => {
              const customKey = `phone'${i}`;
              return (
                <div className="mt-8 first:mt-0 mb-8" key={customKey}>
                  <PhoneField index={i} />
                  <div className="flex justify-between">
                    <PrimaryRadio
                      checked={p.isPrimary}
                      id={`primary-phone-radio-${i}`}
                      onClick={() => selectPrimary(i)}
                      label={t(
                        'edit-contact-information.phone.primary-label',
                        'Primary Phone for RI communication'
                      )}
                    />
                    {!p.isPrimary && <DeleteButton onClick={() => remove(i)} />}
                  </div>
                </div>
              );
            })}
            <AddAnotherButton
              label={t(
                'edit-contact-information.phone.add-label',
                'Add another phone'
              )}
              onClick={() =>
                push({ ...getNewPhoneNumber(), isPrimary: phones.length === 0 })
              }
              color="black"
            />
          </>
        )}
      />
    </div>
  );
};

export default Form;
